import { administrationModules, configurationModules, myPlaneusModules, productionPlanningModules } from "./modules"

export const moduleProductionRoutes = {
  DASHBOARD: "/dashboard",
  PLANNING_OBJECTS: "/planningobjects",
  PLANNING: "/planning",
  WORKBENCH: "/workbench",
  PARTS_SEARCH: "/partssearch",
  CONFLICT_RADAR: "/conflictradar",
  CONFLICT_MATRIX: "/conflictmatrix",
  RESOURCE_GAPS: "/resourcegaps",
  REPORTS: "/reports",
  BASEDATA: "/basedata",
  IMPORT: "/import",
  SIMULATION: "/simulation",
}

export const moduleAdminRoutes = {
  USER_MANAGEMENT: "/usermanagement",
  LICENCES: "/licences",
  TERMINALS: "/workbenchterminal",
  PLANEUSTV_ADMIN: "/planeustvadmin",
  GLOBALSETTINGS: "/globalsettings",
  BACKUP: "/backup",
}

export const moduleConfigRoutes = {
  ARTICLE_GROUPS: "/articlegroups",
  OBJECT_NAMES: "/objectnames",
  AMOUNT_UNITS: "/amountunits",
  RESOURCE_GROUPS: "/resourcegroups",
  HOLIDAYS: "/holidays",
  GAP_TYPES: "/gaptypes",
  TROUBLE_TYPES: "/troubletypes",
  AVAILABILITY_PLANS: "/availabilityplans",
  REJECT_TYPES: "/rejecttypes",
  POOLS: "/pools",
  LOCATIONS: "/locations",
  CHARACTERISTICS: "/characteristics",
}

export const moduleMyPlaneusRoutes = {
  MY_PLANEUS: "/userinfo",
  MY_PLANEUS_MODULE_PREFERENCES: "/modulepreferences",
  MY_PLANEUS_LANGUAGES: "/languages",
}

export const ModuleRoutes = {
  LOGIN: "/login",
  LOGOUT: "/logout",
  WELCOME: "/welcome",

  ...moduleProductionRoutes,
  ...moduleAdminRoutes,
  ...moduleConfigRoutes,
  ...moduleMyPlaneusRoutes,

  PLANNING_OBJECTS_SETTINGS: "/globalsettings/planningObjectsSettings",
  PLANNING_SETTINGS: "/globalsettings/planningSettings",
  WORKBENCH_SETTINGS: "/globalsettings/workbenchSettings",
  IMPORT_SETTINGS: "/globalsettings/importSettings",
  REPORTING_SETTINGS: "/globalsettings/reportingSettings",

  ROLES: "/roles",

  SCREEN: "/planeustvscreen",

  VIDEO_TUTORIALS: "/videotutorials",

  TERMINAL: "/terminals/:terminalId",
}

export const Routes = {
  INDEX: "/",
  CHANGE_LOGS: "/changelogs",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/resetpassword",
  LOGOUT: "/logout/:terminal",
  WELCOME: "/welcome",
  FAKE_USER: "/fakeuser",
  AUTO_LOGOUT: "/autologout",
  AUTO_LOGOUT_TERMINAL: "/autologout/:terminal",

  BASEDATA_DEFAULT: moduleProductionRoutes.BASEDATA,
  BASEDATA_ARTICLES: `${moduleProductionRoutes.BASEDATA}/articles`,
  BASEDATA_ARTICLES_NEW: `${moduleProductionRoutes.BASEDATA}/articles/new`,
  BASEDATA_ARTICLES_COPY: `${moduleProductionRoutes.BASEDATA}/articles/copy`,
  BASEDATA_ARTICLES_EDIT: `${moduleProductionRoutes.BASEDATA}/articles/edit`,
  BASEDATA_ARTICLES_EDIT_BATCH: `${moduleProductionRoutes.BASEDATA}/articles/batchedit`,
  BASEDATA_ARTICLES_ASSEMBLE: `${moduleProductionRoutes.BASEDATA}/articles/assemble`,
  BASEDATA_CUSTOMERS: `${moduleProductionRoutes.BASEDATA}/customers`,
  BASEDATA_CUSTOMERS_NEW: `${moduleProductionRoutes.BASEDATA}/customers/new`,
  BASEDATA_CUSTOMERS_EDIT: `${moduleProductionRoutes.BASEDATA}/customers/edit/:id`,
  BASEDATA_CUSTOMERS_DETAILS: `${moduleProductionRoutes.BASEDATA}/customers/:id`,
  BASEDATA_RESOURCES: `${moduleProductionRoutes.BASEDATA}/resources`,
  BASEDATA_RESOURCES_NEW: `${moduleProductionRoutes.BASEDATA}/resources/new`,
  BASEDATA_RESOURCES_EDIT: `${moduleProductionRoutes.BASEDATA}/resources/edit/:id`,
  BASEDATA_RESOURCES_DETAILS: `${moduleProductionRoutes.BASEDATA}/resources/:id`,
  BASEDATA_TECHNOLOGIES: `${moduleProductionRoutes.BASEDATA}/technologies`,
  BASEDATA_TECHNOLOGIES_EDIT: `${moduleProductionRoutes.BASEDATA}/technologies/edit/:id`,
  BASEDATA_TECHNOLOGIES_DETAILS: `${moduleProductionRoutes.BASEDATA}/technologies/:id`,
  BASEDATA_TEMPLATES: `${moduleProductionRoutes.BASEDATA}/templates`,
  BASEDATA_TEMPLATES_EDIT: `${moduleProductionRoutes.BASEDATA}/templates/edit/:id`,
  BASEDATA_TEMPLATES_DETAILS: `${moduleProductionRoutes.BASEDATA}/templates/:id`,
  BASEDATA_TECHNOLOGYITEMS: `${moduleProductionRoutes.BASEDATA}/technologyItems`,
  BASEDATA_TECHNOLOGYITEMS_EDIT: `${moduleProductionRoutes.BASEDATA}/technologyItems/edit/:id`,
  BASEDATA_TECHNOLOGYITEMS_DETAILS: `${moduleProductionRoutes.BASEDATA}/technologyItems/:id`,
  BASEDATA_MATERIALS: `${moduleProductionRoutes.BASEDATA}/materials`,
  BASEDATA_MATERIALS_NEW: `${moduleProductionRoutes.BASEDATA}/materials/new`,
  BASEDATA_MATERIALS_EDIT: `${moduleProductionRoutes.BASEDATA}/materials/edit/:id`,
  BASEDATA_MATERIALS_DETAILS: `${moduleProductionRoutes.BASEDATA}/materials/:id`,
  BASEDATA_RESOURCE_PRIORITY: `${moduleProductionRoutes.BASEDATA}/resourcepriority`,
  BASEDATA_RESOURCE_PRIORITY_EDIT: `${moduleProductionRoutes.BASEDATA}/resourcepriority/edit/:id`,
  BASEDATA_RESOURCE_PRIORITY_DETAILS: `${moduleProductionRoutes.BASEDATA}/resourcepriority/:id`,

  PARTS_SEARCH_DEFAULT: `${moduleProductionRoutes.PARTS_SEARCH}`,
  PARTS_SEARCH_PROJECT_STATUS: `${moduleProductionRoutes.PARTS_SEARCH}/projectstatus`,
  PARTS_SEARCH_PROJECT_STATUS_FORCE: `${moduleProductionRoutes.PARTS_SEARCH}/projectstatus/:id`,
  PARTS_SEARCH_PARTS_STATUS: `${moduleProductionRoutes.PARTS_SEARCH}/partsstatus`,
  PARTS_SEARCH_PARTS_STATUS_FORCE: `${moduleProductionRoutes.PARTS_SEARCH}/partsstatus/:id`,

  PLANNING_OBJECTS_DEFAULT: moduleProductionRoutes.PLANNING_OBJECTS,
  PLANNING_OBJECTS_ACTIVITIES: `${moduleProductionRoutes.PLANNING_OBJECTS}/activities`,
  PLANNING_OBJECTS_OPERATIONS: `${moduleProductionRoutes.PLANNING_OBJECTS}/operations`,
  PLANNING_OBJECTS_ORDERS: `${moduleProductionRoutes.PLANNING_OBJECTS}/orders`,
  PLANNING_OBJECTS_PRODUCTION_ORDERS: `${moduleProductionRoutes.PLANNING_OBJECTS}/productionorders`,
  PLANNING_OBJECTS_PROJECTS: `${moduleProductionRoutes.PLANNING_OBJECTS}/projects`,
  PLANNING_OBJECTS_PROJECTS_DETAILS: `${moduleProductionRoutes.PLANNING_OBJECTS}/projects/:id`,

  PLANNING_DEFAULT: moduleProductionRoutes.PLANNING,
  PLANNING_TIMELINE: `${moduleProductionRoutes.PLANNING}/timeline`,
  PLANNING_TIMELINE_ACTIVITIES: `${moduleProductionRoutes.PLANNING}/timeline/activity`,
  PLANNING_TIMELINE_CONFLICTS: `${moduleProductionRoutes.PLANNING}/timeline/dateconflict`,
  PLANNING_TIMELINE_ORDERPOSITIONS: `${moduleProductionRoutes.PLANNING}/timeline/orderPosition`,
  PLANNING_TIMELINE_PROJECTS: `${moduleProductionRoutes.PLANNING}/timeline/project`,

  PRODUCTION_TIMELINE: `${moduleProductionRoutes.PLANNING}/production`,
  PRODUCTION_TIMELINE_PRODUCTIONORDERS: `${moduleProductionRoutes.PLANNING}/production/productionOrder`,
  PRODUCTION_TIMELINE_OPERATIONS: `${moduleProductionRoutes.PLANNING}/production/operation`,
  PRODUCTION_TIMELINE_CONFLICTS: `${moduleProductionRoutes.PLANNING}/production/conflict`,

  WORKBENCH_DEFAULT: moduleProductionRoutes.WORKBENCH,
  WORKBENCH_SCAN_OPERATIONS: `${moduleProductionRoutes.WORKBENCH}/scanOperations`,
  WORKBENCH_SCAN_OPERATIONS_DETAILS: `${moduleProductionRoutes.WORKBENCH}/scanOperations/:operationId`,
  WORKBENCH_ACTIVITIES: `${moduleProductionRoutes.WORKBENCH}/activities`,
  WORKBENCH_ACTIVITIES_DETAILS: `${moduleProductionRoutes.WORKBENCH}/activities/:activityId`,
  WORKBENCH_ACTIVITIES_PROGRESS: `${moduleProductionRoutes.WORKBENCH}/activities/:activityId/:type/:progressId`,

  AMOUNT_UNITS: moduleConfigRoutes.AMOUNT_UNITS,
  AMOUNT_UNITS_NEW: `${moduleConfigRoutes.AMOUNT_UNITS}/new`,
  AMOUNT_UNITS_EDIT: `${moduleConfigRoutes.AMOUNT_UNITS}/edit`,

  POOLS: moduleConfigRoutes.POOLS,
  POOLS_NEW: `${moduleConfigRoutes.POOLS}/new`,
  POOLS_EDIT: `${moduleConfigRoutes.POOLS}/edit`,
  TERMINALS: moduleAdminRoutes.TERMINALS,
  TERMINALS_NEW: `${moduleAdminRoutes.TERMINALS}/new`,
  TERMINALS_EDIT: `${moduleAdminRoutes.TERMINALS}/edit`,
  PLANEUSTV_ADMIN: moduleAdminRoutes.PLANEUSTV_ADMIN,
  PLANEUSTV_ADMIN_DETAILS: `${moduleAdminRoutes.PLANEUSTV_ADMIN}/:id`,
  PLANEUSTV_ADMIN_EDIT: `${moduleAdminRoutes.PLANEUSTV_ADMIN}/edit/:id`,
  PLANEUSTV_ADMIN_NEW: `${moduleAdminRoutes.PLANEUSTV_ADMIN}/new`,
  ARTICLE_GROUPS: moduleConfigRoutes.ARTICLE_GROUPS,
  ARTICLE_GROUPS_NEW: `${moduleConfigRoutes.ARTICLE_GROUPS}/new`,
  ARTICLE_GROUPS_EDIT: `${moduleConfigRoutes.ARTICLE_GROUPS}/edit`,
  GAP_TYPES: moduleConfigRoutes.GAP_TYPES,
  GAP_TYPES_NEW: `${moduleConfigRoutes.GAP_TYPES}/new`,
  GAP_TYPES_EDIT: `${moduleConfigRoutes.GAP_TYPES}/edit`,
  TROUBLE_TYPES: moduleConfigRoutes.TROUBLE_TYPES,
  TROUBLE_TYPES_NEW: `${moduleConfigRoutes.TROUBLE_TYPES}/new`,
  TROUBLE_TYPES_EDIT: `${moduleConfigRoutes.TROUBLE_TYPES}/edit`,
  REJECT_TYPES: moduleConfigRoutes.REJECT_TYPES,
  REJECT_TYPES_NEW: `${moduleConfigRoutes.REJECT_TYPES}/new`,
  REJECT_TYPES_EDIT: `${moduleConfigRoutes.REJECT_TYPES}/edit`,
  AVAILABILITY_PLANS: moduleConfigRoutes.AVAILABILITY_PLANS,
  AVAILABILITY_PLANS_NEW: `${moduleConfigRoutes.AVAILABILITY_PLANS}/new`,
  AVAILABILITY_PLANS_EDIT: `${moduleConfigRoutes.AVAILABILITY_PLANS}/edit`,
  HOLIDAYS: moduleConfigRoutes.HOLIDAYS,
  HOLIDAYS_NEW: `${moduleConfigRoutes.HOLIDAYS}/new`,
  HOLIDAYS_EDIT: `${moduleConfigRoutes.HOLIDAYS}/edit`,
  OBJECT_NAMES: moduleConfigRoutes.OBJECT_NAMES,
  OBJECT_NAMES_NEW: `${moduleConfigRoutes.OBJECT_NAMES}/new`,
  OBJECT_NAMES_EDIT: `${moduleConfigRoutes.OBJECT_NAMES}/edit`,
  RESOURCE_GAPS: moduleProductionRoutes.RESOURCE_GAPS,
  RESOURCE_GROUPS: moduleConfigRoutes.RESOURCE_GROUPS,
  RESOURCE_GROUPS_NEW: `${moduleConfigRoutes.RESOURCE_GROUPS}/new`,
  RESOURCE_GROUPS_EDIT: `${moduleConfigRoutes.RESOURCE_GROUPS}/edit`,
  CONFIGURATION: ModuleRoutes.CONFIGURATION,
  DASHBOARD: moduleProductionRoutes.DASHBOARD,
  CONFLICT_MATRIX: moduleProductionRoutes.CONFLICT_MATRIX,
  CONFLICT_RADAR: moduleProductionRoutes.CONFLICT_RADAR,
  CONFLICT_RADAR_DETAILS: `${moduleProductionRoutes.CONFLICT_RADAR}/:id`,
  CONFLICT_OPERATIONS: `${moduleProductionRoutes.CONFLICT_RADAR}/operations`,
  CONFLICT_OPERATIONS_DELAYED: `${moduleProductionRoutes.CONFLICT_RADAR}/operationsDelayed`,
  IMPORT: moduleProductionRoutes.IMPORT,
  LOCATIONS: moduleConfigRoutes.LOCATIONS,
  LOCATIONS_DETAILS: `${moduleConfigRoutes.LOCATIONS}/:id`,
  LOCATIONS_EDIT: `${moduleConfigRoutes.LOCATIONS}/edit`,
  LOCATIONS_NEW: `${moduleConfigRoutes.LOCATIONS}/new`,
  SIMULATION: moduleProductionRoutes.SIMULATION,

  CHARACTERISTICS_DEFAULT: moduleConfigRoutes.CHARACTERISTICS,
  CHARACTERISTICS_PHYSICAL: `${moduleConfigRoutes.CHARACTERISTICS}/physical`,
  CHARACTERISTICS_PHYSICAL_DETAILS: `${moduleConfigRoutes.CHARACTERISTICS}/physical/:id`,
  CHARACTERISTICS_PHYSICAL_EDIT: `${moduleConfigRoutes.CHARACTERISTICS}/physical/edit`,
  CHARACTERISTICS_PHYSICAL_NEW: `${moduleConfigRoutes.CHARACTERISTICS}/physical/new`,
  CHARACTERISTICS_FREE: `${moduleConfigRoutes.CHARACTERISTICS}/free`,
  CHARACTERISTICS_FREE_DETAILS: `${moduleConfigRoutes.CHARACTERISTICS}/free/:id`,
  CHARACTERISTICS_FREE_EDIT: `${moduleConfigRoutes.CHARACTERISTICS}/free/edit`,
  CHARACTERISTICS_FREE_NEW: `${moduleConfigRoutes.CHARACTERISTICS}/free/new`,
  CHARACTERISTICS_ORDER: `${moduleConfigRoutes.CHARACTERISTICS}/order`,
  CHARACTERISTICS_ORDER_DETAILS: `${moduleConfigRoutes.CHARACTERISTICS}/order/:id`,
  CHARACTERISTICS_ORDER_EDIT: `${moduleConfigRoutes.CHARACTERISTICS}/order/edit`,
  CHARACTERISTICS_ORDER_NEW: `${moduleConfigRoutes.CHARACTERISTICS}/order/new`,

  // Adminstration Module
  USER_MANAGEMENT_DEFAULT: moduleAdminRoutes.USER_MANAGEMENT,
  USER_MANAGEMENT_USERS: `${moduleAdminRoutes.USER_MANAGEMENT}/users`,
  USER_MANAGEMENT_USERS_NEW: `${moduleAdminRoutes.USER_MANAGEMENT}/users/new`,
  USER_MANAGEMENT_USERS_EDIT: `${moduleAdminRoutes.USER_MANAGEMENT}/users/edit/:id/:tabId`,
  USER_MANAGEMENT_USERS_DETAILS: `${moduleAdminRoutes.USER_MANAGEMENT}/users/:id`,
  USER_MANAGEMENT_USER_ROLES: `${moduleAdminRoutes.USER_MANAGEMENT}/userroles`,
  USER_MANAGEMENT_USER_ROLES_EDIT: `${moduleAdminRoutes.USER_MANAGEMENT}/userroles/edit/:id`,

  AUTHORIZATION_LICENCES: moduleAdminRoutes.LICENCES,
  GLOBALSETTINGS: moduleAdminRoutes.GLOBALSETTINGS,
  PLANNING_OBJECTS_SETTINGS: ModuleRoutes.PLANNING_OBJECTS_SETTINGS,
  PLANNING_SETTINGS: ModuleRoutes.PLANNING_SETTINGS,
  WORKBENCH_SETTINGS: ModuleRoutes.WORKBENCH_SETTINGS,
  IMPORT_SETTINGS: ModuleRoutes.IMPORT_SETTINGS,
  REPORTING_SETTINGS: ModuleRoutes.REPORTING_SETTINGS,
  BACKUP: moduleAdminRoutes.BACKUP,

  MY_PLANEUS_DEFAULT: moduleMyPlaneusRoutes.MY_PLANEUS,
  MY_PLANEUS_USERINFO: moduleMyPlaneusRoutes.MY_PLANEUS,
  MY_PLANEUS_MODULE_PREFERENCES: moduleMyPlaneusRoutes.MY_PLANEUS_MODULE_PREFERENCES,
  MY_PLANEUS_LANGUAGES: moduleMyPlaneusRoutes.MY_PLANEUS_LANGUAGES,

  VIDEO_TUTORIALS: ModuleRoutes.VIDEO_TUTORIALS,
  VIDEO_TUTORIALS_DETAIL: `${ModuleRoutes.VIDEO_TUTORIALS}/:topic/:id`,

  REPORTS_DEFAULT: moduleProductionRoutes.REPORTS,
  REPORTS: moduleProductionRoutes.REPORTS,
  REPORTS_TABLES: `${moduleProductionRoutes.REPORTS}/table`,
  REPORTS_OPERATIONS_PROGRESSES: `${moduleProductionRoutes.REPORTS}/progressesOperations`,
  REPORTS_OPERATIONS_PROGRESSES_DETAILS: `${moduleProductionRoutes.REPORTS}/progressesOperations/:id`,
  REPORTS_ACTIVITIES_PROGRESSES: `${moduleProductionRoutes.REPORTS}/progressesActivities`,
  REPORTS_ACTIVITIES_PROGRESSES_DETAILS: `${moduleProductionRoutes.REPORTS}/progressesActivities/:id`,

  TERMINAL: ModuleRoutes.TERMINAL,
  TERMINAL_CHOOSE_WORK: `${ModuleRoutes.TERMINAL}/chooseWork`,
  TERMINAL_WORKLIST: `${ModuleRoutes.TERMINAL}/worklist/`,
  TERMINAL_WORKLIST_MACHINE: `${ModuleRoutes.TERMINAL}/worklist/machine/:machineId`,
  TERMINAL_WORKLIST_MYWORK: `${ModuleRoutes.TERMINAL}/worklist/mywork`,
  TERMINAL_START: `${ModuleRoutes.TERMINAL}/start`,

  TVSCREEN: `${ModuleRoutes.SCREEN}/:screen`,
}

export const AreaRouteMapping = {
  affectedoperations: Routes.CONFLICT_OPERATIONS,
  articles: Routes.BASEDATA_ARTICLES,
  resources: Routes.BASEDATA_RESOURCES,
  technologies: Routes.BASEDATA_TECHNOLOGIES,
  templates: Routes.BASEDATA_TEMPLATES,
  customers: Routes.BASEDATA_CUSTOMERS,
  technologyItems: Routes.BASEDATA_TECHNOLOGYITEMS,
  material: Routes.BASEDATA_MATERIALS,
  resourcepriority: Routes.BASEDATA_RESOURCE_PRIORITY,

  timeline: Routes.PLANNING_TIMELINE,
  production: Routes.PRODUCTION_TIMELINE,

  activities: Routes.PLANNING_OBJECTS_ACTIVITIES,
  operations: Routes.PLANNING_OBJECTS_OPERATIONS,
  orders: Routes.PLANNING_OBJECTS_ORDERS,
  productionOrders: Routes.PLANNING_OBJECTS_PRODUCTION_ORDERS,
  projects: Routes.PLANNING_OBJECTS_PROJECTS,

  dashboard: Routes.DASHBOARD,
  workbench: Routes.WORKBENCH,
  workbenchscan: Routes.WORKBENCH_SCAN_OPERATIONS,
  workbenchactivities: Routes.WORKBENCH_ACTIVITIES,
  conflictMatrix: Routes.CONFLICT_MATRIX,
  conflicts: Routes.CONFLICT_RADAR,
  delayed: Routes.CONFLICT_OPERATIONS_DELAYED,
  import: Routes.IMPORT,

  users: Routes.USER_MANAGEMENT_USERS,
  userroles: Routes.USER_MANAGEMENT_USER_ROLES,

  partssearch: Routes.PARTS_SEARCH_DEFAULT,
  projectStatus: Routes.PARTS_SEARCH_PROJECT_STATUS,
  partsStatus: Routes.PARTS_SEARCH_PARTS_STATUS,
  planningObjectsSettings: ModuleRoutes.PLANNING_OBJECTS_SETTINGS,
  planningSettings: ModuleRoutes.PLANNING_SETTINGS,
  workbenchSettings: ModuleRoutes.WORKBENCH_SETTINGS,
  importSettings: ModuleRoutes.IMPORT_SETTINGS,
  reportingSettings: ModuleRoutes.REPORTING_SETTINGS,

  characteristicsFree: Routes.CHARACTERISTICS_FREE,
  characteristicsOrder: Routes.CHARACTERISTICS_ORDER,
  characteristicsPhysical: Routes.CHARACTERISTICS_PHYSICAL,
}

export const ModuleRouteMapping = {
  planning: moduleProductionRoutes.PLANNING,
  planningreadonly: moduleProductionRoutes.PLANNING,
  planningObjects: moduleProductionRoutes.PLANNING_OBJECTS,
  planningObjectsreadonly: moduleProductionRoutes.PLANNING_OBJECTS,
  basedata: moduleProductionRoutes.BASEDATA,
  basedatareadonly: moduleProductionRoutes.BASEDATA,

  // Administration Module
  userManagement: moduleAdminRoutes.USER_MANAGEMENT,
  licences: moduleAdminRoutes.LICENCES,
  globalsettings: moduleAdminRoutes.GLOBALSETTINGS,
  backup: moduleAdminRoutes.BACKUP,

  roles: ModuleRoutes.ROLES,

  dashboard: moduleProductionRoutes.DASHBOARD,
  workbench: moduleProductionRoutes.WORKBENCH,
  partssearch: moduleProductionRoutes.PARTS_SEARCH,
  conflictMatrix: moduleProductionRoutes.CONFLICT_MATRIX,
  conflictRadar: moduleProductionRoutes.CONFLICT_RADAR,
  conflictRadarreadonly: moduleProductionRoutes.CONFLICT_RADAR,
  import: moduleProductionRoutes.IMPORT,
  automatedimport: moduleProductionRoutes.IMPORT,

  articleGroups: moduleConfigRoutes.ARTICLE_GROUPS,
  pools: moduleConfigRoutes.POOLS,
  locations: moduleConfigRoutes.LOCATIONS,
  workbenchterminal: moduleAdminRoutes.TERMINALS,
  calendar: moduleConfigRoutes.HOLIDAYS,
  gaplist: moduleProductionRoutes.RESOURCE_GAPS,
  gaptypes: moduleConfigRoutes.GAP_TYPES,
  troubletypes: moduleConfigRoutes.TROUBLE_TYPES,
  rejecttypes: moduleConfigRoutes.REJECT_TYPES,
  availabilityplans: moduleConfigRoutes.AVAILABILITY_PLANS,
  objectNames: moduleConfigRoutes.OBJECT_NAMES,
  resourceGroups: moduleConfigRoutes.RESOURCE_GROUPS,
  units: moduleConfigRoutes.AMOUNT_UNITS,
  characteristics: moduleConfigRoutes.CHARACTERISTICS,

  administration: moduleAdminRoutes.USER_MANAGEMENT,
  configuration: moduleAdminRoutes.USER_MANAGEMENT,
  planeusscreen: moduleAdminRoutes.PLANEUSTV_ADMIN,
  welcome: ModuleRoutes.WELCOME,
  userinfo: moduleMyPlaneusRoutes.MY_PLANEUS,
  modulepreferences: moduleMyPlaneusRoutes.MY_PLANEUS_MODULE_PREFERENCES,
  languages: moduleMyPlaneusRoutes.MY_PLANEUS_LANGUAGES,

  reports: moduleProductionRoutes.REPORTS,
  reportingplugins: moduleProductionRoutes.REPORTS,
  simulation: moduleProductionRoutes.SIMULATION,
}

export const rootMenuRouteMapping = {
  productionPlanning: productionPlanningModules.dashboard,
  configuration: configurationModules.articleGroups,
  administration: administrationModules.userManagement,
  myPlaneus: myPlaneusModules.userinfo,
  reports: productionPlanningModules.reports,
}
