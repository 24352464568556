import StatusTypes from "../types/StatusTypes"
import { lightgreen, lightSkyBlue, orange } from "../constants/colors"

const statusStyles = {
  [StatusTypes.notPlanned]: "#FD9A00",
  [StatusTypes.replaced]: orange,
  [StatusTypes.planned]: "#20AAEA",
  [StatusTypes.started]: "#4186E0",
  [StatusTypes.interrupted]: "#E8384F",
  [StatusTypes.done]: lightgreen,
}

const statusWorkbenchStyles = {
  [StatusTypes.notPlanned]: "#FD9A00",
  [StatusTypes.replaced]: orange,
  [StatusTypes.planned]: lightSkyBlue,
  [StatusTypes.started]: lightgreen,
  [StatusTypes.interrupted]: "#E8384F",
  [StatusTypes.done]: lightgreen,
  [StatusTypes.paused]: "#1095DE",
}

export const styleColorById = id => statusStyles[id]
export const styleWorkbenchColorById = id => statusWorkbenchStyles[id]
